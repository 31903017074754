import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=2ebccf9e&scoped=true"
import script from "./menu.vue?vue&type=script&lang=js"
export * from "./menu.vue?vue&type=script&lang=js"
import style0 from "../donation.scss?vue&type=style&index=0&prod&lang=scss&module=m&external"
import style1 from "./menu.vue?vue&type=style&index=1&id=2ebccf9e&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["m"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "2ebccf9e",
  null
  
)

export default component.exports